<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcAddLocationContact }}</h1>
      <h4>{{ location.org_name }}</h4>
      <section class="section-3 bg-block mb-4">
        <div>
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">{{ translations.tcContactPerson }}</h2>
            <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
          </header>
          <b-form v-show="Section1" validated>
            <b-row v-if="!this.indixo.ixo_ind_key">
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element" :label="`${translations.tcFirstName}:`" label-for="first-name-input"
                  label-class="form-group-label">
                  <b-form-input id="first-name-input" :title="translations.tcFirstName" required
                    :placeholder="translations.tcFirstName" v-model="indixo.ind_first_name"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!this.indixo.ixo_ind_key">
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element" :label="`${translations.tcLastName}:`" label-for="last-name-input"
                  label-class="form-group-label">
                  <b-form-input id="last-name-input" :title="translations.tcLastNameName" required
                    :placeholder="translations.tcLastName" v-model="indixo.ind_last_name"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="this.indixo.ixo_ind_key">
              <b-col sm="6" class="font-style-3">
                <h2 class="font-style-1">{{ indixo.ind_first_name }} {{ indixo.ind_last_name }}</h2>
              </b-col>
            </b-row>
            <b-row v-if="showPrimary">
              <b-col sm="3" class="font-style-3">
                <b-form-checkbox id="checkbox-primary" v-model="indixo.primary" name="checkbox-primary" value="true"
                  class="mb-3" unchecked-value="false"></b-form-checkbox>&nbsp;
                <h3 class="font-style-2 check_label">{{ translations.tcIsPrimary }}</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element" :label="`${translations.tcTitle}:`" label-for="title-input"
                  label-class="form-group-label">
                  <b-form-input id="title-input" :title="translations.tcTitle" required
                    :placeholder="translations.tcTitle" v-model="indixo.ixo_title"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element" :label="`${translations.tcRelationship}:`" label-for="title-input"
                  label-class="form-group-label">
                  <b-form-select :title="translations.tcRelationship" required
                    :options="optionsRelationshipTypesTranslated" v-model="indixo.ixo_irt_key"
                    class="g-select flex-0 mr-3 mb-3">
                    <template slot="first">
                      <option :value="null" disabled>{{ translations.tcRelationship }}</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </section>
      <section class="section-3 bg-block mb-4" v-if="!this.indixo.ixo_ind_key">
        <div class="body">
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">
              {{ translations.tcCommunications }}
            </h2>
            <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
          </header>
          <b-form v-show="Section2">
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element" :label="`${translations.tcPhone}:`" label-for="phone-input"
                  label-class="form-group-label">
                  <b-form-input id="phone-input" :title="translations.tcPhone" :placeholder="translations.tcPhone"
                    v-model="indixo.phone_number" required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="font-style-3">
                <b-form-group class="form-element" :label="`${translations.tcEmail}:`" label-for="email-input"
                  label-class="form-group-label">
                  <b-form-input id="email-input" :title="translations.tcEmail" :placeholder="translations.tcEmail"
                    v-model="indixo.email" required></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
            translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
            translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'location-add-contact',
  mixins: [translationMixin],
  data() {
    return {
      optionsRelationshipTypesTranslated: {},
      translations: {},
      repOptions: [
        {
          text: 'Gideon Representative',
          value: '4ce90b3a-c10d-49d0-b9d7-646cc370f1c8',
        },
      ],
      location: {},
      indixo: {},
      selectedContactKey: null,
      searchedContact: [],
      Section1: true,
      Section2: true,
      Section3: true,
    }
  },
  methods: {
    ...mapActions({
      addLocationContact: 'scriptureDistribution/addLocationContact',
      loadPlacementContactRelationshipTypeOptions: 'scriptureDistribution/loadPlacementContactRelationshipTypeOptions',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedLocationContact: 'user/setSelectedLocationContact',
      setShowPrimary: 'scriptureDistribution/setShowPrimary',
    }),
    async handleCancelClick() {
      this.$router.push('/programs/sd/location-contacts')
    },
    async prepareIndividual() {
      this.location = await this.detail
    },
    async prepareData() {
      this.indixo.ixo_org_key = this.userSelectedLocationKey
      this.indixo.ixo_user = this.userId
      this.indixo.ind_lng_key = this.userLanguageKey
      this.indixo.ind_user = this.userId
      this.indixo.ind_key = this.indixo.ixo_ind_key
      this.indixo.primary = this.showPrimary ? this.isPrimaryFlag() : false
    },
    async handleSaveClick() {
      if (this.validateForm()) {
        await Promise.all([this.setLoadingStatus(true), await this.prepareData(), await this.addContact()]).then(
          (rst) => {
            let result = rst[2]
            this.setLoadingStatus(false)
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.translations.tcLocationContactSaved : this.translations.tcErrorSavingLocationContact,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then((result2) => {
              if (!!result) {
                this.indixo = { ...this.empty_individual_ixo }
                this.setSelectedLocationContact(null)
                this.setShowPrimary(true)
                this.$router.push('/programs/sd/location-contacts')
              }
            })
          }
        )
      }
    },
    isPrimaryFlag() {
      if (this.locationContacts && this.locationContacts.length === 0) {
        return true
      } else {
        return this.indixo.primary || false
      }
    },
    async addContact() {
      let result = await this.addLocationContact(this.indixo)
      return result
    },
    async applyFoundPerson(evt) {
      this.searchedContact.push({ value: evt.ind_key, text: evt.name_text })
      this.selectedContactKey = evt.ind_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    validateForm() {
      let rtn = true
      if (
        this.indixo.ind_first_name === '' ||
        this.indixo.ind_last_name === '' ||
        this.indixo.ixo_title === null ||
        this.indixo.ixo_irt_key === null
      ) {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorFieldsMissing,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          return false
        })
      }
      return rtn
    },
    async loadContactForEdit() {
      if (!this.userSelectedLocationContact) {
        this.indixo = { ...this.empty_individual_ixo }
        return
      } else {
        if (!!this.userSelectedLocationContact && !!this.userSelectedLocationContact.ind_first_name) {
          var pre_indixo = await this.locationContacts.find(
            (ck) => ck.ixo_ind_key == this.userSelectedLocationContact.ixo_ind_key
          )
          this.selectedContactKey = this.userSelectedLocationContact.ixo_ind_key
          pre_indixo.primary = pre_indixo.primary_ind_word === 'Yes' ? true : false
          this.indixo = { ...pre_indixo }
        }
      }
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.indixo = { ...this.empty_individual_ixo }
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('common.individual-relationship-type'),
        await this.loadPlacementContactRelationshipTypeOptions(),
        await this.prepareIndividual(),
        await this.loadContactForEdit(),
      ]).then((results) => {
        this.stripReadableText(results[1])
        const translatedText = { common: { ...this.translations.common } }
        this.$set(this.translations, 'components', translatedText)

        this.optionsRelationshipTypes = this.convertedContactRelationshipTypeFormOptions.types
        this.translateDropdownOptions([{ node: 'individual-relationship-type', store: 'optionsRelationshipTypes' }])
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      contactRelationshipTypeFormOptions: 'scriptureDistribution/contactRelationshipTypeFormOptions',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      convertedContactRelationshipTypeFormOptions: 'scriptureDistribution/convertedContactRelationshipTypeFormOptions',
      detail: 'scriptureDistribution/locationDetail',
      empty_individual_ixo: 'churchMinistry/empty_individual_ixo',
      locationContacts: 'scriptureDistribution/locationContacts',
      prefCulture: 'user/userPreferredCulture',
      showPrimary: 'scriptureDistribution/showPrimary',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      userSelectedLocationContact: 'user/userSelectedLocationContact',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
    }),
    optionsGenderList() {
      return [{ value: null, text: 'Select a gender', disabled: true }, ...constantData.gender_list]
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.form-group-label {
  margin-bottom: 0;
  color: #636363;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
}

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
